.k-dialog-titlebar{
    background-color: #002664;
}

.k-dialog-button-layout-stretched{
    padding: 10px;
}

.au-btn-margin-left{
    margin-left:20px;
}

.au-control-input-markin-top{
    margin-top:10px;
}

.au-callout-hover{
    position: absolute;
    z-index:9999;
    left:0px;
}