.allow-vertical-columns th
{
    text-align: center;
    vertical-align: bottom;
    height: 330px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.verticalText
{
    text-align: center;
    vertical-align: middle;
    width: 10px;
    margin: 0px;
    padding: 0px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 10px;
    white-space: nowrap;
    /* Chrome/Safari */
    -webkit-transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
    /* Firefox */
    -moz-transform: rotate(-90deg);
    -moz-transform-origin: 50% 50%;
    /* IE9 */
    -ms-transform: rotate(-90deg);
    -ms-transform-origin: 50% 50%;
    /* This should work for IE10 and other modern browsers that do not need vendor prefixes */
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    /* IE8 or less - using the "\9" CSS hack so that other browsers will ignore these lines */
    zoom: 1\9;
    writing-mode: tb-rl\9;
    filter: flipv fliph;              
};